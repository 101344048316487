<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row class="my-3">
      <v-col>
        <v-btn
          color="primary"
          @click.stop="dialogs.create = true"
        >
          Programar Notificacion
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        offset-md="6"
        offset-lg="8"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          light
        >
          <template v-slot:[`item.sent`]="{ item }">
            <span :class="item.sent ? 'success--text' : 'error--text' ">
              <b>
                {{ item.sent ? 'Enviado' : 'Sin Enviar' }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.next_notification`]="{ item }">
            {{ getLocalTime(item.next_notification) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <!-- <v-icon
              :color="item.status ? 'error' : 'success' "
              @click="setStatus(item.id, !item.status)"
            >
              {{ item.status ? 'mdi-close-circle' : 'mdi-check-circle' }}
            </v-icon> -->
            <v-icon
              @click="updateDialog(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="remove(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogs.create"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Programar Notificacion
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="forms.create.title"
                label="Titulo"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="forms.create.body"
                label="Mensaje"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="forms.create.link"
                label="Link"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <date-picker
                v-model="forms.create.date"
                label="Fecha"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <time-picker
                v-model="forms.create.time"
                label="Hora"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                v-for="(error, i) in forms.create.errors"
                :key="error-i"
                class="error--text"
              >
                {{ error }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="create"
          >
            Crear
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.create = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.update"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Actualizar Grupo Alimenticio
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="forms.update.title"
                label="Titulo"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="forms.update.body"
                label="Mensaje"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="forms.update.link"
                label="Link"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <date-picker
                v-model="forms.update.date"
                label="Fecha"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <time-picker
                v-model="forms.update.time"
                label="Mensaje"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                v-for="(error, i) in forms.update.errors"
                :key="error-i"
                class="error--text"
              >
                {{ error }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="update"
          >
            Actualizar
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.update = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'

  export default {
    data () {
      return {
        headers: [
          { text: 'Titulo', value: 'title' },
          { text: 'Mensaje', value: 'body' },
          { text: 'Link', value: 'link' },
          { text: 'Hora', value: 'next_notification' },
          { text: 'Estado', value: 'sent' },
          { text: 'Acciones', value: 'action' },
        ],
        items: [],
        search: null,
        dialogs: {
          create: false,
          update: false,
        },
        forms: {
          create: {
            title: null,
            body: null,
            date: null,
            time: null,
            link: null,
            errors: [],
          },
          update: {
            id: null,
            title: null,
            body: null,
            date: null,
            time: null,
            link: null,
            errors: [],
          },
        },
      }
    },
    async mounted () {
      await this.getItems()
    },
    methods: {
      getLocalTime (utc) {
        const format = 'YYYY-MM-DD HH:mm'
        return moment.utc(utc, format).local().format(format)
      },
      async getItems () {
        this.items = await this.$api.Schedule.list()
      },
      async create () {
        const form = this.forms.create
        const timestamp = `${form.date} ${form.time}`
        const nextNotification = moment(timestamp, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm:ssZ')
        const payload = {
          title: form.title,
          body: form.body,
          link: form.link,
          next_notification: nextNotification,
          // time: moment(form.time, 'HH:mm').utc().format('HH:mm:ss'),
        }
        try {
          const response = await this.$api.Schedule.create(payload)
          this.items.push(response)
          form.title = null
          form.body = null
          form.time = null
          form.date = null
          form.link = null
          this.dialogs.create = false
        } catch (err) {
          const response = err.response
          const data = response.data
          form.errors.splice(0, form.errors.length)
          if (response.status === 400) {
            if ('msg' in data && data.msg === 'Already exists') {
              form.errors.push('El grupo alimenticio ya existe')
            }
          }
        }
      },
      async updateDialog (item) {
        const form = this.forms.update
        form.id = item.id
        form.title = item.title
        form.body = item.body
        form.link = item.link

        const timestamp = this.getLocalTime(item.next_notification).split(' ')
        form.date = timestamp.length > 0 ? timestamp[0] : null
        form.time = timestamp.length > 1 ? timestamp[1] : null
        this.dialogs.update = true
      },
      async update () {
        const form = this.forms.update
        const timestamp = `${form.date} ${form.time}`
        const nextNotification = moment(timestamp, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm:ssZ')
        const payload = {
          title: form.title,
          body: form.body,
          link: form.link,
          next_notification: nextNotification,
          // time: moment(form.time, 'HH:mm').utc().format('HH:mm:ss'),
        }
        try {
          const response = await this.$api.Schedule.update(form.id, payload)
          const index = this.items.map(x => x.id).indexOf(form.id)
          this.items.splice(index, 1, response)
          this.dialogs.update = false
        } catch (err) {

        }
      },
      async remove (item) {
        try {
          await this.$api.Schedule.remove(item.id)
          const index = this.items.map(x => x.id).indexOf(item.id)
          this.items.splice(index, 1)
        } catch (err) {

        }
      },
      async setStatus (id, status) {
        const response = await this.$api.FoodGroup.setStatus(id, status)
        const index = this.items.map(x => x.id).indexOf(id)
        this.items.splice(index, 1, response)
      },
    },
  }
</script>
